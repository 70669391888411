import React from 'react';
import '../../assets/styles/loader.sass';

const Loader = ({ loading }) => {
  if (!loading) return <></>;
  return (
    <div className="loading-container">
      <div className="spin" />
    </div>
  );
};

export default Loader;
