import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
import reportWebVitals from './reportWebVitals';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import './index.css';

const Tracker = lazy(() => import('./components/Tracker'));
const Booker = lazy(() => import('./components/Booker'));
const RedirectToMobile = lazy(() => import('./components/RedirectToMobile'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader loading={true} />}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<RedirectToMobile />} />
          <Route path="book" element={<Booker />} />
          <Route path="tracker" element={<Tracker />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
    ,
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
